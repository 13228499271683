export enum AppointmentUserType {
  PARENT = "parent",
  ETUDIENT = "etudiant",
}

export enum mailType {
  CANDIDAT_HAVE_YOU_BEEN_CONTACTED = "CANDIDAT_HAVE_YOU_BEEN_CONTACTED",
  CFA_REMINDER_RESEND_APPOINTMENT = "CFA_REMINDER_RESEND_APPOINTMENT",
  CANDIDAT_APPOINTMENT = "CANDIDAT_APPOINTMENT",
}
