import { z } from "zod"

export const ZEtablissementCatalogue = z
  .object({
    _id: z.string(),
    siege_social: z.boolean(),
    etablissement_siege_siret: z.string(),
    siret: z.string(),
    siren: z.string(),
    nda: z.string(),
    naf_code: z.string(),
    naf_libelle: z.string(),
    date_creation: z.date(),
    diffusable_commercialement: z.boolean(),
    enseigne: z.null(),
    onisep_nom: z.null(),
    onisep_url: z.null(),
    onisep_code_postal: z.null(),
    adresse: z.string(),
    numero_voie: z.string().nullable(),
    type_voie: z.string().nullable(),
    nom_voie: z.string().nullable(),
    complement_adresse: z.string(),
    code_postal: z.string(),
    num_departement: z.string(),
    nom_departement: z.string(),
    localite: z.string(),
    code_insee_localite: z.string(),
    cedex: z.null(),
    date_fermeture: z.date(),
    ferme: z.boolean(),
    region_implantation_code: z.string(),
    region_implantation_nom: z.string(),
    commune_implantation_code: z.string(),
    commune_implantation_nom: z.string(),
    num_academie: z.number(),
    nom_academie: z.string(),
    uai: z.string(),
    info_datagouv_ofs: z.null(),
    info_datagouv_ofs_info: z.null(),
    info_qualiopi_info: z.string(),
    api_entreprise_reference: z.boolean(),
    entreprise_siren: z.string(),
    entreprise_procedure_collective: z.boolean(),
    entreprise_enseigne: z.null(),
    entreprise_numero_tva_intracommunautaire: z.null(),
    entreprise_raison_sociale: z.string(),
    entreprise_nom_commercial: z.null(),
    entreprise_date_creation: z.date(),
    entreprise_date_radiation: z.date(),
    entreprise_naf_code: z.string(),
    entreprise_naf_libelle: z.string(),
    entreprise_date_fermeture: z.date(),
    entreprise_ferme: z.boolean(),
    entreprise_siret_siege_social: z.string(),
    entreprise_nom: z.null(),
    entreprise_prenom: z.null(),
    formations_attachees: z.null(),
    formations_ids: z.array(z.any()),
    formations_uais: z.null(),
    published: z.boolean(),
    updates_history: z.array(z.any()),
    tags: z.array(z.string()),
    rco_uai: z.string(),
    rco_adresse: z.null(),
    rco_code_postal: z.string(),
    rco_code_insee_localite: z.string(),
    idcc: z.null(),
    opco_nom: z.null(),
    opco_siren: z.null(),
    created_at: z.date(),
    last_update_at: z.date(),
    __v: z.number(),
    entreprise_tranche_effectif_salarie: z.null(),
    etablissement_siege_id: z.null(),
    geo_coordonnees: z.string(),
    rco_geo_coordonnees: z.null(),
    uai_valide: z.boolean(),
    certifie_qualite: z.boolean(),
    date_mise_a_jour: z.null(),
    ds_id_dossier: z.null(),
    ds_questions_ask_for_certificaton_date: z.null(),
    ds_questions_declaration_code: z.null(),
    ds_questions_has_2020_training: z.null(),
    ds_questions_has_agrement_cfa: z.null(),
    ds_questions_has_ask_for_certificaton: z.null(),
    ds_questions_has_certificaton_2015: z.null(),
    ds_questions_nom: z.null(),
    ds_questions_siren: z.null(),
    ds_questions_uai: z.null(),
    entreprise_capital_social: z.null(),
    entreprise_categorie: z.null(),
    entreprise_code_effectif_entreprise: z.null(),
    entreprise_forme_juridique: z.string(),
    entreprise_forme_juridique_code: z.string(),
    formations_n3: z.null(),
    formations_n4: z.null(),
    formations_n5: z.null(),
    formations_n6: z.null(),
    formations_n7: z.null(),
    pays_implantation_code: z.string(),
    pays_implantation_nom: z.string(),
    tranche_effectif_salarie: z.null(),
    uais_potentiels: z.null(),
    update_error: z.null(),
  })
  .strict()

export type IEtablissementCatalogue = z.output<typeof ZEtablissementCatalogue>

export const ZEtablissementCatalogueProche = ZEtablissementCatalogue.pick({
  _id: true,
  siret: true,
  numero_voie: true,
  type_voie: true,
  nom_voie: true,
  code_postal: true,
  nom_departement: true,
  entreprise_raison_sociale: true,
  geo_coordonnees: true,
})

export type IEtablissementCatalogueProche = z.output<typeof ZEtablissementCatalogueProche>

export const ZEtablissementCatalogueProcheWithDistance = ZEtablissementCatalogueProche.extend({
  distance_en_km: z.number(),
})
export type IEtablissementCatalogueProcheWithDistance = z.output<typeof ZEtablissementCatalogueProcheWithDistance>
